import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

const supportedLangs: string[] = ["zh-hant", "en-us"];
const isLangSupport = (lang: string): boolean => {
  return supportedLangs.indexOf(lang) >= 0;
};

const setLanguage = (lang: string) => {
  if (!lang || supportedLangs.indexOf(lang) < 0) {
    lang = supportedLangs[0];
  }
  i18n.global.locale.value = lang;
};

/** load all language file in locale directory */
const loadLocaleMessages = (): {
  [x: string]: LocaleMessages<VueMessageType>;
} => {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: { [x: string]: LocaleMessages<VueMessageType> } = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
};

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "zh-hant",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "zh-hant",
  messages: loadLocaleMessages(),
});
export default i18n;
export { setLanguage, isLangSupport, supportedLangs };
