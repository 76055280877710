import Notify from 'quasar/src/plugins/Notify.js';;

const notifyTimeout = 3000;

export const errorNotify = (msg: string) =>
  Notify.create({
    type: "negative",
    color: "negative",
    message: msg,
    timeout: notifyTimeout,
    position: "top",
  });
