import _ from "lodash";
import moment from "moment";
import i18n from "./i18n";

export const getDateWithoutTime = (dt: string): string => {
  let result = "";
  if (dt !== "") {
    const momentParse = moment(dt).utcOffset(8);
    result = momentParse.format("yyyy/MM/DD");
    // const dtTmp = new Date(Date.parse(dt));
    // result = dtTmp.toLocaleDateString();
  }
  return result;
};

export const getDateWithTime = (dt: string): string => {
  const dtTmp = new Date(Date.parse(dt));
  return dtTmp.toLocaleString();
};

export const courseTimeConvert = (
  courseTimes: Array<{ day: number; openTime: string; closeTime: string }>
): Array<{ week: string; time: string[] }> => {
  const result: Array<{ week: string; time: string[] }> = [];
  const groupTime = _.groupBy(courseTimes, "day");
  if (groupTime) {
    _.forIn(groupTime, (value, key) => {
      const parse: { week: string; time: string[] } = { week: "", time: [] };
      parse.week = i18n.global.t(`time.week[${parseInt(key) - 1}]`);
      parse.time = _.map(value, (item) => {
        const openMoment = moment(item.openTime, "HHmm").utcOffset(8);
        const closeMoment = moment(item.closeTime, "HHmm").utcOffset(8);

        const isZhant = i18n.global.locale.value === "zh-hant";
        const openFormat = openMoment.format("A");
        const openFormatLang = isZhant
          ? i18n.global.t(`time.format.${openFormat}`)
          : openFormat;

        const closeFormat = closeMoment.format("A");
        const closeFormatLang = isZhant
          ? i18n.global.t(`time.format.${closeFormat}`)
          : closeFormat;

        const result = isZhant
          ? `${openFormatLang} ${openMoment.format(
              "hh:mm"
            )}-${closeFormatLang} ${closeMoment.format("hh:mm")}`
          : `${openMoment.format("hh:mm")} ${openFormat}-${closeMoment.format(
              "hh:mm"
            )} ${closeFormat}`;
        return result;
      });
      result.push(parse);
    });
  }
  return result;
};
