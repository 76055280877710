import { CourseTimeType } from "@/common/models/CourseTimeType";

/**
 * Course card data
 */
export default class CourseCardPropDTO {
  public image = "";
  public name = "";
  public address = "";
  public date = "";
  public timeType: CourseTimeType = CourseTimeType.None;
  public timeDescription = "";
  public remain = 0;
  public courseTimes: CourseTime[] = [];
}

class CourseTime {
  public day = 0;
  public openTime = "";
  public closeTime = "";
}
