import _ from "lodash";
import IssueRedeemPropDTO, {
  Contract,
} from "@/views/LatestEvent/models/IssueRedeemPropDTO";
import { EventType } from "@/views/models/EventType";
import CourseCardPropDTO from "@/views/LatestEvent/models/CourseCardPropDTO";
import { getDateWithoutTime } from "@/plugins/utils";
import { CardResp, MerchantCardsResp } from "@/services/liff/models";
import { AccountResp } from "@/services/account.service";

interface MerchantState {
  profile: AccountResp;
  points: CardResp[]; // 顧客擁有的點卡與票券
  course: CardResp[]; // 顧客擁有的課卡
  tokens: CardResp[]; // 商家啟用中的點卡
}

const state: MerchantState = {
  profile: new AccountResp(),
  points: [],
  course: [],
  tokens: [],
};

export default {
  state,
  getters: {
    getPoints: (state: MerchantState): IssueRedeemPropDTO[] => {
      const issueList: IssueRedeemPropDTO[] = _.filter(
        state.tokens,
        (token) => {
          const issueCommon = _.filter(
            token.issueContracts,
            (issue) => issue.isMemberTrigger === false
          );
          return issueCommon.length > 0;
        }
      ).map((token) => {
        const userToken = _.find(state.points, { address: token.address });
        const userAmount: number = userToken?.amount ?? 0;
        const result = new IssueRedeemPropDTO({
          id: token.address as string,
          name: token.name,
          image: token.image,
          amount: userAmount,
          contracts: [] as Contract[],
          type: EventType.Issue,
        });

        result.contracts = _.filter(
          token.issueContracts,
          (issue) => issue.isMemberTrigger === false
        ).map((issue) => {
          const result = new Contract();
          result.mapFromApiModel(issue);
          return result;
        });
        return result;
      });
      return issueList;
    },
    getOnlineIssue: (state: MerchantState): IssueRedeemPropDTO[] => {
      const onlineIssue: IssueRedeemPropDTO[] = _.filter(
        state.tokens,
        (token) => {
          const issueCommon = _.filter(
            token.issueContracts,
            (issue) => issue.isMemberTrigger === true
          );
          return issueCommon.length > 0;
        }
      ).map((token) => {
        const userToken = _.find(state.points, { address: token.address });
        const userAmount: number = userToken?.amount ?? 0;
        const result = new IssueRedeemPropDTO({
          id: token.address as string,
          name: token.name,
          image: token.image,
          amount: userAmount,
          contracts: [] as Contract[],
          type: EventType.Issue,
        });

        result.contracts = _.filter(
          token.issueContracts,
          (issue) => issue.isMemberTrigger === true
        ).map((issue) => {
          const result = new Contract();
          result.mapFromApiModel(issue);
          return result;
        });
        return result;
      });
      return onlineIssue;
    },
    getRedeems: (state: MerchantState): IssueRedeemPropDTO[] => {
      const redeemList: IssueRedeemPropDTO[] = _.filter(
        state.tokens,
        (token) => {
          const redeemCommon = _.filter(
            token.redeemContracts,
            (redeem) => redeem.isMemberTrigger === false
          );
          return redeemCommon.length > 0;
        }
      ).map((token) => {
        const userToken = _.find(state.points, { address: token.address });
        const userAmount: number = userToken?.amount ?? 0;
        const result = new IssueRedeemPropDTO({
          id: token.address as string,
          name: token.name,
          image: token.image,
          amount: userAmount,
          contracts: [] as Contract[],
          type: EventType.Redeem,
        });
        result.contracts = _.filter(
          token.redeemContracts,
          (redeem) => redeem.isMemberTrigger === false
        ).map((redeem) => {
          const result = new Contract();
          result.mapFromApiModel(redeem);
          return result;
        });
        return result;
      });
      return redeemList;
    },
    getOnlineRedeems: (state: MerchantState): IssueRedeemPropDTO[] => {
      const redeemList: IssueRedeemPropDTO[] = _.filter(
        state.tokens,
        (token) => {
          const redeemCommon = _.filter(
            token.redeemContracts,
            (redeem) => redeem.isMemberTrigger === true
          );
          return redeemCommon.length > 0;
        }
      ).map((token) => {
        const userToken = _.find(state.points, { address: token.address });
        const userAmount: number = userToken?.amount ?? 0;

        const result = new IssueRedeemPropDTO({
          id: token.address as string,
          name: token.name,
          image: token.image,
          amount: userAmount,
          contracts: [] as Contract[],
          type: EventType.Redeem,
        });
        result.contracts = _.filter(
          token.redeemContracts,
          (redeem) => redeem.isMemberTrigger === true
        ).map((redeem) => {
          const result = new Contract();
          result.mapFromApiModel(redeem);
          return result;
        });
        return result;
      });
      return redeemList;
    },
    getCourse: (state: MerchantState): CourseCardPropDTO[] => {
      const courseList = _.filter(
        state.course,
        (token) => token.merchantAddress === state.profile.walletAddress
      );
      const result: CourseCardPropDTO[] = [];
      for (const item of courseList) {
        const parse = new CourseCardPropDTO();
        parse.name = item.name;
        parse.address = item.address;
        parse.image = item.image;
        parse.timeType = item.courseTimeType;
        parse.timeDescription = item.courseTimeDescription ?? "";
        parse.date = `${getDateWithoutTime(
          item.startOn
        )} ~ ${getDateWithoutTime(item.endOn)}`;
        parse.remain = item.amount ?? 0;
        parse.courseTimes = item.courseTimes;
        result.push(parse);
      }
      return result;
    },
    getETickets(state: MerchantState): IssueRedeemPropDTO[] {
      const ticketList: IssueRedeemPropDTO[] = _.filter(
        state.points,
        (point) => {
          if (!_.find(state.tokens, { address: point.address })) {
            return false;
          }

          const ticketCommon = _.filter(
            point.redeemContracts,
            (redeem) => redeem.isVoucher
          );
          return ticketCommon.length > 0;
        }
      ).map((point) => {
        const result = new IssueRedeemPropDTO({
          id: point.address as string,
          name: point.name,
          image: point.image,
          amount: point.amount ?? 0,
          contracts: [] as Contract[],
          type: EventType.Redeem,
        });

        result.contracts = _.filter(
          point.redeemContracts,
          (redeem) => redeem.isVoucher
        ).map((redeem) => {
          const result = new Contract();
          result.mapFromApiModel(redeem);
          return result;
        });
        return result;
      });
      return ticketList;
    },
    getProfile: (state: MerchantState): AccountResp => {
      return state.profile;
    },
  },
  mutations: {
    setProfile(state: MerchantState, profile: AccountResp) {
      state.profile = profile;
    },
    setPoint(state: MerchantState, points: CardResp[]) {
      state.points = points;
    },
    setCourse(state: MerchantState, courses: CardResp[]) {
      state.course = courses;
    },
    setCards(state: MerchantState, merchantCards: MerchantCardsResp) {
      state.tokens = merchantCards.points;
    },
  },
  namespaced: true,
};
