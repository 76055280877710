import moment from "moment";
import {
  ContractResp,
  ContractType,
} from "@/services/liff/models/ContractResp";
import { SchemaType } from "@/common/models";
import { EventType } from "@/views/models/EventType";

/**
 * Point card or eticket data
 */
export default class IssueRedeemPropDTO {
  public id = "";
  public name = "";
  public image = "";
  public amount = 0;
  public type: EventType = EventType.Issue;
  public contracts: Contract[] = [];
  public constructor(fields: {
    id: string;
    name: string;
    image: string;
    amount: number;
    type: EventType;
    contracts: Contract[];
  }) {
    Object.assign(this, fields);
  }
}

class Contract {
  public id = "";
  public name = "";
  public image = "";
  public description = "";
  public address = "";
  public point = 0;
  public during = "";
  public type = SchemaType.None;
  public isMemberTrigger = false;
  public isTriggerOnClick = false;
  public isVoucher = false;

  public mapFromApiModel(model: ContractResp) {
    this.id = model.address;
    this.name = model.name;
    this.image = model.image;
    this.description = model.description;
    this.address = model.address;
    this.isMemberTrigger = model.isMemberTrigger;
    this.isTriggerOnClick = model.isTriggerOnClick;
    this.isVoucher = model.isVoucher;

    if (model.contractType === ContractType.Issue) {
      this.point = model.issueAmount ?? 0;
      this.during = `${moment(model.startOn)
        .utc()
        .utcOffset(8)
        .format("yyyy/MM/DD")} - ${moment(model.endOn)
        .utc()
        .utcOffset(8)
        .format("yyyy/MM/DD")}`;
    } else {
      this.point = model.redeemAmount ?? 0;
      this.during = `${moment(model.endOn)
        .utc()
        .utcOffset(8)
        .format("yyyy/MM/DD")}`;
    }

    // Obselete. There won't be empty schema in production environment.
    if (model.contractStandard === "IssuePerConsumption_0") {
      model.schema = SchemaType.issueBySpentV2;
    }

    this.type = model.schema;
  }
}

export { Contract };
