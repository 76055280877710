export enum SchemaType {
  None = "None",
  issueAnyAmountV2 = "issueAnyAmountV2",
  issueFixedV2 = "issueFixedV2",
  issueRandomV2 = "issueRandomV2",
  issueBySpentV2 = "issueBySpentV2",
  issueByItemCountV2 = "issueByItemCountV2",
  redeemFixedV2 = "redeemFixedV2",
  redeemAnyAmountV2 = "redeemAnyAmountV2",
}
