interface BindState {
  linkToken: string;
  memberId: string;
}

const state: BindState = {
  linkToken: "",
  memberId: "",
};

export default {
  state,
  getters: {},
  actions: {},
  mutations: {
    setLinkToken(state: BindState, token: string) {
      state.linkToken = token;
    },
    setMemberId(state: BindState, memberId: string) {
      state.memberId = memberId;
    },
  },
  namespaced: true,
};
