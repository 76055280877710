<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
export default {
  name: "LayoutDefault",
  components: {},
  setup() {
    return {};
  },
};
</script>
