import axios, { AxiosResponse } from "axios";
const configPath = "/appConfig.json";
const envConfig: ConfigType = {
  EULA_URL: "",
  LINEBOT_BACKEND_URL: "",
};

export const getConfigEnv = async (): Promise<ConfigType> => {
  const resp: AxiosResponse<ConfigType> = await axios.get(configPath);
  if (resp.status === 200) {
    Object.assign(envConfig, resp.data);
  }
  return envConfig;
};

type ConfigType = {
  EULA_URL: string;
  LINEBOT_BACKEND_URL: string;
};
