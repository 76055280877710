import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-symbols-outlined/material-symbols-outlined.css";
import Loading from 'quasar/src/plugins/Loading.js';import Cookies from 'quasar/src/plugins/Cookies.js';import QSpinnerPuff from 'quasar/src/components/spinner/QSpinnerPuff.js';import Notify from 'quasar/src/plugins/Notify.js';;

export default {
  /* There is a type issue in `QuasarPluginOptions`, so use any type here.
   * Upgrade to newer Quasar version may solve this. */
  config: {
    loading: {
      spinner: QSpinnerPuff,
      spinnerColor: "deep-orange-8",
      backgroundColor: "transparent",
    },
  } as any,
  plugins: {
    Loading,
    Cookies,
    Notify,
  },
};
