import _ from "lodash";
import moment from "moment";
import { TransactionResp } from "@/services/liff/models";
import TradeInfoPropDTO, {
  TradeContent,
} from "@/views/History/models/TradeInfoPropDTO";

interface HistoryState {
  point: TransactionResp[];
  course: TransactionResp[];
}

const state: HistoryState = {
  point: [],
  course: [],
};

export default {
  state,
  getters: {
    getPoint: (state: HistoryState): TradeInfoPropDTO[] => {
      const result: TradeInfoPropDTO[] = [];

      const groupData = _.groupBy(state.point, (item) => {
        return moment(item.transactionOn).utc().format("YYYY/MM/DD");
      });
      for (const key in groupData) {
        const item = groupData[key];
        const parse = new TradeInfoPropDTO();
        parse.date = key;
        for (const [index, subItem] of item.entries()) {
          const content = new TradeContent();
          content.mapFromApiModel(subItem);
          content.id = index.toString();
          parse.data.push(content);
        }
        result.push(parse);
      }
      return result;
    },
    getCourse: (state: HistoryState): TradeInfoPropDTO[] => {
      const result: TradeInfoPropDTO[] = [];

      const groupData = _.groupBy(state.course, (item) => {
        return moment(item.transactionOn).utc().format("YYYY/MM/DD");
      });
      for (const key in groupData) {
        const item = groupData[key];
        const parse = new TradeInfoPropDTO();
        parse.date = key;
        for (const [index, subItem] of item.entries()) {
          const content = new TradeContent();
          content.mapFromApiModel(subItem);
          content.id = index.toString();
          parse.data.push(content);
        }
        result.push(parse);
      }
      return result;
    },
  },
  mutations: {
    setCourse(state: HistoryState, course: TransactionResp[]) {
      state.course = course;
    },
    setPoint(state: HistoryState, point: TransactionResp[]) {
      state.point = point;
    },
  },
  namespaced: true,
};
