import api from "@/plugins/api";
import Loading from 'quasar/src/plugins/Loading.js';;
import axios, { AxiosResponse, AxiosError } from "axios";
import { errorNotify } from "@/plugins/notify";
import { IApiResponse } from "@/common/models";

export default class AccountService {
  public async GetProfile(accountId: string): Promise<AccountResp | undefined> {
    try {
      const resp: AxiosResponse<IApiResponse<AccountResp>> = await api.get(
        `Account/${accountId}`
      );
      return resp.data.data;
    } catch (error) {
      Loading.hide();
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<string>;
        errorNotify(axiosError.message);
      }
    }
  }
}

export class AccountResp {
  id = "";
  walletAddress = "";
  name = "";
  icon = "";
}
