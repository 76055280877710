import { createStore } from "vuex";
import merchant from "./merchant";
import history from "./history";
import bind from "./bind";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { merchant, history, bind },
});
